import * as React from "react";
import { fetchCount, postCount } from "../services/api";

const CountPage = () => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let res = await fetchCount();
    if (res?.count) {
      console.log("res?.count", res?.count);
      setCount(res?.count);
    }
    console.log("res", res);
    let local_view = window?.localStorage?.getItem("agrid_view");
    if (!local_view) {
      /** API CALL */
      res = await postCount();
      if (res?.count) {
        console.log("res?.count", res?.count);
        setCount(res?.count);
      }
      console.log("init");
      window?.localStorage?.setItem("agrid_view", 1);
    } else {
    }
  };

  return (
    <div
      style={{
        color: "#0C446F",
        fontFamily: "Arial",
        fontSize: "2.5em",
        fontWeight: "600",
        lineHeight: "1.2em",
      }}
    >
      <span>
        Grâce à vous,{" "}
        {count > 0 ? (
          <span>
            nous allons planter {count} arbre{count > 1 ? "s" : ""} !
          </span>
        ) : (
          "..."
        )}
      </span>
    </div>
  );
};

export default CountPage;
