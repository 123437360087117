let base_url = "https://api.a-grid.com";

export const fetchCount = async () => {
  return fetch(`${base_url}/api/count`)
    .then((res) => res.json())
    .then(
      (result) => {
        console.log("res", result);
        return result;
      },
      (error) => {
        return null;
      }
    );
};

export const postCount = async () => {
  return fetch(`${base_url}/api/count`, { method: "POST" })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log("res", result);
        return result;
      },
      (error) => {
        return null;
      }
    );
};
